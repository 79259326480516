import React from "react";

import { Grid } from "@mui/material";

import PersonCard from "../Components/PersonCard";

export default function We() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      spacing={2}
    >
      <PersonCard
        imagePath={"/people/lucie"}
        title={"Lucie"}
        description={<a href="#">Nevěsta</a>}
      />
      <PersonCard
        imagePath={"/people/martin"}
        title={"Martin"}
        description={<a href="#">Ženich</a>}
      />
    </Grid>
  );
}
