import { Grid } from "@mui/material";
import React from "react";

export default function Video() {
  const width = (+window.innerWidth > 1200 ? 1200 : +window.innerWidth) - 32; // 32 is 2x16 padding
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <iframe
          style={{ margin: "16px 0 0 16px" }}
          width={width}
          height={width * 0.5625}
          src="https://www.youtube.com/embed/eq2wNVtpwfA?si=kMDpdxBSbABP6k8s"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Grid>
    </React.Fragment>
  );
}
