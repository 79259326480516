import React from "react";

import { Grid } from "@mui/material";

import PlaceCard from "../Components/PlaceCard";

export default function Places() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      spacing={2}
    >
      <PlaceCard
        key={"kostelicek"}
        imagePaths={["/places/kostelicek"]}
        mapLink={"https://mapy.cz/s/capafezacu"}
        gMapLink={"https://maps.app.goo.gl/wLP7hh9ePcUttpRQ8"}
        title={"Obřad"}
        description={"Kostelíček BLUDOV"}
      />
      <PlaceCard
        key={"blud"}
        imagePaths={["/places/bludov"]}
        mapLink={"https://mapy.cz/s/favokefoku"}
        gMapLink={"https://goo.gl/maps/Ruwp2WUqrgf42NXB6"}
        title={"Svatební hostina, Večerní párty"}
        description={"Kulturní dům BLUDOV"}
      />
    </Grid>
  );
}
