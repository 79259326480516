import React from "react";

import { Typography } from "@mui/material";

export default function Gifts() {
  return (
    <Typography align="center">
      Hrníčků i talířů už máme dosti,
      <br />
      proto přijímáme svatební dary v hotovosti.
      <br />
      Děkujeme
    </Typography>
  );
}