import React, { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { makeStyles } from 'tss-react/mui';
import {
  AppBar,
  Box,
  Paper,
  Tab,
  TableContainer,
  Tabs,
  Typography,
} from '@mui/material';

import ProgramTabPanel from '../Components/ProgramTabPanel';
import { IWedType } from '../types';

const useStyles = makeStyles()({
  tableContainer: {
    maxWidth: 600,
    minWidth: 300,
  },
  box: {
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Program() {
  const { classes } = useStyles();
  const [value, setValue] = React.useState(
      !!localStorage.getItem('svatba_type')
      ? // @ts-ignore
        +localStorage.getItem('svatba_type')
      : 0
  );

  useEffect(() => {
    localStorage.setItem('svatba_type', value.toString());
  }, [value]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <>
      <Typography variant="h5" align="center">
        jdu na...
      </Typography>
      <Box my={4} className={classes.box}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              centered
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Obřad" {...a11yProps(0)} />
              <Tab label="Svatební hostinu" {...a11yProps(1)} />
              <Tab label="Večerní párty" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
            <ProgramTabPanel value={value} index={0} />
            <ProgramTabPanel value={value} index={1} />
            <ProgramTabPanel value={value} index={2} />
          </SwipeableViews>
        </TableContainer>
      </Box>
    </>
  );
}
