import React from "react";

import { Grid } from "@mui/material";

import PersonCard from "../Components/PersonCard";

export default function Driver() {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <PersonCard
          imagePath={"/people/ridic1"}
          title={"Filip Benáček"}
          description={<a href="tel:732322897">Řidič Fidla - 732 322 897</a>}
        />
        <PersonCard
          imagePath={"/people/ridic2"}
          title={"Vojtěch Haltmar"}
          description={<a href="tel:734645264">Řidič Vojta - 734 645 264</a>}
        />
      </Grid>
    </React.Fragment>
  );
}
