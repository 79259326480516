import React from "react";

import { Grid } from "@mui/material";

import PlaceCard from "../Components/PlaceCard";

export default function Parking() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      spacing={2}
    >
      <PlaceCard
        key={"rap-park"}
        imagePaths={["/places/kostelicekPark"]}
        mapLink={"https://mapy.cz/s/peguvasuda"}
        title={"Obřad - parkování"}
        description={"Kostelíček BLUDOV"}
        onClick={() =>
          window.open(
            "/places/kostelicekParkFull.jpg",
            "popUpWindow",
            "height=800,width=800,scrollbars=yes,menubar=no"
          )
        }
        style={{ cursor: "pointer" }}
      />
      <PlaceCard
        key={"blud-park"}
        imagePaths={["/places/bludovPark"]}
        mapLink={"https://mapy.cz/s/nofemohale"}
        title={"Svatební hostina, Večerní párty - parkování"}
        description={"Kulturní dům BLUDOV"}
        onClick={() =>
          window.open(
            "/places/bludovParkFull.jpg",
            "popUpWindow",
            "height=800,width=800,left=10,top=10,,scrollbars=yes,menubar=no"
          )
        }
        style={{ cursor: "pointer" }}
      />
    </Grid>
  );
}
