import React from "react";

import Contacts from "./Pages/Contacts";
import Gifts from "./Pages/Gifts";
import Info from "./Pages/Info";
import Invitation from "./Pages/Invitation";
import Places from "./Pages/Places";
import Program from "./Pages/Program";
import We from "./Pages/We";
import Date from "./Pages/Date";
import Driver from "./Pages/Driver";
import Parking from "./Pages/Parking";
import Photos from "./Pages/Photos";
import Video from "./Pages/Video";

export const DRAWER_WIDTH = 240;

export const CATEGORIES = {
  Úvod: <></>,
  Datum: <Date />,
  Snoubenci: <We />,
  Fotky: <Photos />,
  Video: <Video />,
  Pozvánka: <Invitation />,
  Informace: <Info />,
  Program: <Program />,
  Místa: <Places />,
  Parkování: <Parking />,
  Dary: <Gifts />,
  Kontakty: <Contacts />,
  Řidiči: <Driver />,
};
