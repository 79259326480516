import React from "react";

import { makeStyles } from "tss-react/mui";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { tableDataType } from "../types";

const useStyles = makeStyles()({
  table: {
    margin: "0 auto",
    width: "300px",
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
});

function createData(time: string, activity: string) {
  return { time, activity };
}
const commonRows = [createData("11:00", "Obřad")];
const commonParty = [createData("17:00", "Večerní párty")];
const rows = [
  [...commonRows],
  [
    ...commonRows,
    createData("13:30", "Svatební hostina"),
    createData("14:30", "Krájení svatebního dortu, podávání kávy"),
    createData("15:00", "Rodinné focení, odvoz aut"),
    createData("16:00", "První novomanželský tanec"),
    ...commonParty,
  ],
  [...commonRows, ...commonParty],
];

function ProgramTabPanel(props: {
  value: number;
  index: number;
  customRows?: tableDataType;
}) {
  const { classes } = useStyles();
  const { value, index, customRows } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <Table className={classes.table} aria-label="program table">
          <TableBody>
            {(customRows || rows[value]).map((row) => (
              <TableRow key={row.time}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  className={classes.tableCell}
                >
                  {row.time}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {row.activity}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}

export default ProgramTabPanel;
