import React, { useEffect, useState } from "react";

import { AppBar, Toolbar } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { isTablet } from "../../utils";
import DesktopMenu from "./DesktopMenu";
import LeftDrawer from "./LeftDrawer";
import MobileMenu from "./MobileMenu ";
import { ICategoriesKeysProps } from "../../types";
import { primaryColor } from "../../theme";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: primaryColor,
  },
}));

export default function Navigation(props: ICategoriesKeysProps) {
  const { categories } = props;
  const [isTabletState, setIsTabletState] = useState(isTablet());
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => setIsTabletState(isTablet()));
    return () =>
      window.removeEventListener("resize", () => setIsTabletState(isTablet()));
  }, []);

  return (
    <React.Fragment>
      <AppBar position="sticky" className={classes.root}>
        <Toolbar>
          {isTabletState ? (
            <MobileMenu setOpen={setOpen} />
          ) : (
            <DesktopMenu categories={categories} />
          )}
        </Toolbar>
      </AppBar>
      {isTabletState && (
        <LeftDrawer open={open} setOpen={setOpen} categories={categories} />
      )}
    </React.Fragment>
  );
}
