import { Grid } from "@mui/material";
import React from "react";

import PhotoCard from "../Components/PhotoCard";

export default function Photos() {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <PhotoCard
          key={"foto"}
          imagePaths={["svatebniFotky"]}
          zoneramaLink={
            "https://eu.zonerama.com/lianviktorie/1382108"
          }
          title={"Svatební fotky"}
          description={"Heslo: svatbaml"}
        />
      </Grid>
    </React.Fragment>
  );
}
