import React from "react";

import { Grid } from "@mui/material";

import PersonCard from "../Components/PersonCard";

export default function Contacts() {
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        <PersonCard
          imagePath={"/people/d"}
          title={"Daniela Znojová"}
          description={<a href="tel:734221595">Svědkyně - 734 221 595</a>}
        />
        <PersonCard
          imagePath={"/people/ondra"}
          title={"Ondřej Daniel"}
          description={<a href="tel:720300880">Svědek - 720 300 880</a>}
        />
      </Grid>
    </React.Fragment>
  );
}
