import React, { useEffect, useState } from "react";

import { Paper, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { isMobile } from "../../utils";

const useStyles = makeStyles()((theme: Theme, params, classes) => ({
  slideshowPaper: {
    margin: "0px 5px -2px 5px",
    height: "31vw",
    maxHeight: "400px",
    minHeight: "130px",
  },
  slideshowImages: {
    objectFit: "cover",
    minHeight: "130px",
    width: "100%",
  },
}));

export default function CarouselItem(props: { imagePath: string }) {
  const { classes } = useStyles();
  const { imagePath } = props;
  const [isMobileState, setIsMobileState] = useState(isMobile());

  useEffect(() => {
    window.addEventListener("resize", () => setIsMobileState(isMobile()));
    return () =>
      window.removeEventListener("resize", () => setIsMobileState(isMobile()));
  }, []);

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.slideshowPaper}>
        <img
          className={classes.slideshowImages}
          src={`/${isMobileState ? "sImages" : "images"}/${imagePath}.jpg`}
          alt={imagePath}
        ></img>
      </Paper>
    </React.Fragment>
  );
}
