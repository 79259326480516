import React from "react";

import { Button, Divider, List, ListItem, ListItemText } from "@mui/material";

import { scrollToCategory } from "../utils";
import { CATEGORIES } from "../constants";

export default function Info() {
  return (
    <React.Fragment>
      <List>
        <ListItem>
          <ListItemText
            primary="SOUTĚŽ: fotka místa ze svatebního oznámení se nazývá a nachází na jakém místě?"
            secondary="Své odpovědi posílejte ženichovi do 20.05.2024. V odpovědi zašlete název místa polohu místa v Mapy.cz. Každý má pouze jeden pokus. Pokud jdete na svatbu v páru či jako rodina, počítá se to jako jeden pokus. Prvních 5 lidí, kteří uhodnou název a polohu místa obdrží dárek v den svatby."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Na večerní párty jsou zváni všichni ti, kdo k svatebnímu
                oznámení obdrželi i kartičku s
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() => window.open("/invite/raut.jpg", "_blank")}
                >
                  pozvánkou na večerní párty
                </Button>
                či jim to bylo přímo řečeno, podobně tak s
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() => window.open("/invite/hostina.jpg", "_blank")}
                >
                  pozváním na svatební hostinu
                </Button>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Prosíme, aby hosté zvaní na svatební hostinu, přicházeli do kulturního domu zadním vchodem - od paneláků."
            secondary="Hosty přicházející na večerní párty rádi přivítáme u hlavního vchodu - od kostela."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Prosíme, nedělejte nepořádek u nevěsty na zahradě v předvečer svatby."
            secondary="Nikomu by to radost neudělalo a bez této tradice se rádi obejdeme."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Prosíme, neparkujte před vchodem do kulturního domu v Bludově.
                Využijte místa uvedená v sekci
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    scrollToCategory(
                      Object.keys(CATEGORIES).indexOf("Parkování")
                    )
                  }
                >
                  Parkování
                </Button>
                .
              </>
            }
            secondary="Místo je rezervováno pro novomanžele a svatebního řidiče."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Prosíme, nepřipravujte únos nevěsty."
            secondary="Rádi bychom prožili svatební den spolu a se svatebními hosty."
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Na svatební hostinu se prosím dopravte sami, odvoz z hostiny na
                ubytování/domů zajišťují naši
                <Button
                  color="primary"
                  key={`kontaktyButton`}
                  onClick={() =>
                    scrollToCategory(Object.keys(CATEGORIES).indexOf("Řidiči"))
                  }
                >
                  svatební řidiči
                </Button>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Na svatbě bude přítomen fotograf, který dokáže zachytit důležité
                okamžiky mnohem lépe než Vaše mobilní telefony. Proto Vás
                prosíme nedělejte během obřadu vlastní fotografie, ať není
                narušena slavnostní atmosféra. Svatební fotografie od
                profesionála budete mít následně k dispozici i vy.
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                Do dne svatby se prosím obracejte s dotazy na{" "}
                <Button
                  color="primary"
                  key={"snoubenci"}
                  onClick={() => scrollToCategory(2)}
                >
                  snoubence
                </Button>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={
              <>
                V den svatby se prosím obracejte na
                {
                  <Button
                    color="primary"
                    key={`kontaktyButton`}
                    onClick={() =>
                      scrollToCategory(
                        Object.keys(CATEGORIES).indexOf("Kontakty")
                      )
                    }
                  >
                    Svědka a svědkyni
                  </Button>
                }
              </>
            }
          />
        </ListItem>
        <Divider />
      </List>
    </React.Fragment>
  );
}
