import scrollIntoView from 'smooth-scroll-into-view-if-needed';

export const getRandomizedArray = (
  a: number[],
  b?: number,
  c?: number,
  d?: number
): number[] => {
  //array,placeholder,placeholder,placeholder
  c = a.length;
  while (c) (b = (Math.random() * c--) | 0), (d = a[c]), (a[c] = a[b]), (a[b] = d);
  return a;
};

export const isMobile = () => window.innerWidth <= 425;
export const isTablet = (onlyTablet?: boolean) =>
  (!onlyTablet || !isMobile()) && window.innerWidth <= 956;
export const isDesktop = () => !isTablet();

export const scrollToCategory = (index: number) => {
  const node = document.getElementById(`category-${index}`);
  if (node) {
    // non-standard library due to poor browser support of 'smooth' behavior
    scrollIntoView(node, {
      behavior: 'smooth',
      block: 'start',
      scrollMode: 'always',
    });
  }
};
