import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/cs";

import { Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  gridInnerItem: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    maxWidth: "375px",
  },
}));

export default function Date() {
  const { classes } = useStyles();
  moment.locale("cs");
  const weddingDate: moment.Moment = moment("2024-05-25 11:00");

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const future: moment.Moment = weddingDate.clone();
      const now: moment.Moment = moment();
      const d = future.diff(now, "days");
      const h = future.subtract(d, "days").diff(now, "hours");
      const m = future.subtract(h, "hours").diff(now, "minutes");
      const s = future.subtract(m, "minutes").diff(now, "seconds");
      setDays(d);
      setHours(h);
      setMinutes(m);
      setSeconds(s);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <Typography
          variant="h3"
          color="primary"
          style={{ fontFamily: "Tangerine" }}
        >
          <b>{`Sobota 25. 5. 2024`}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <Grid item xs={12} className={classes.gridInnerItem}>
          <Typography variant="subtitle2"></Typography>
          <Typography variant="body2">cca {weddingDate.fromNow()}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridInnerItem}>
          <Typography variant="subtitle2"> </Typography>
          <Typography variant="body2">{`za ${days} dní, ${hours} hodin, ${minutes} minut, ${seconds} vteřin`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
